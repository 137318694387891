import { TiptapPrimaryTextNodeView, TiptapPrimaryTextContent, TiptapPrimaryTextSummary } from './node-views/tiptap-primary-text-nodeview';
import { CollabModel } from 'flux-diagram';
import { TEXT_SHAPE_DEF_ID } from './../../../../editor/interaction/text-interaction-handler';
import { ViewportToDiagramCoordinate } from 'apps/nucleus/src/base/coordinate/viewport-to-diagram-coordinate.svc';
import { isEmpty } from 'lodash';
import { ChildEditorNodeView } from './node-views/child-editor-nodeview';
import { IframeNodeView } from './node-views/iframe-node-view';
import { Authentication, UserInfoModel, UserLocator } from 'flux-user';
import { TranslateService } from '@ngx-translate/core';
import { ImageTiptapNodeView } from './node-views/image-embed-tiptap-nodeview';
import { INodeView } from './node-views/node-view.i';
import { DiagramLinkTiptapNodeView } from './node-views/diagramlink-tiptap-nodeview';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DEFUALT_TEXT_STYLES, IDataItem, ITextContent } from 'flux-definition';
import { DiagramLocatorLocator } from 'apps/nucleus/src/base/diagram/locator/diagram-locator-locator';
import { IDataItemUIControl } from '../data-items-uic.i';
import { AppConfig, CommandService, Logger, Rectangle, Tracker } from 'flux-core';
import { StateService } from 'flux-core';
import { DynamicComponent } from 'flux-core/src/ui';
import { Component, ChangeDetectionStrategy, ElementRef,
  AfterViewInit, ComponentFactoryResolver, Injector, ViewChild, ViewContainerRef, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Subject, fromEvent, merge, Observable, Observer, of } from 'rxjs';
import { Editor, getHTMLFromFragment } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Node } from '@tiptap/core';
import { suggestionCmd, TiptapSlashCommandsService } from './slash-commands/tiptap-slash-commands-service';
import { DataItemTiptapNodeView } from './node-views/tiptap-dataItem-nodeview';
import Underline from '@tiptap/extension-underline';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Table from '@tiptap/extension-table';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';
import Typography from '@tiptap/extension-typography';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextDirection from 'tiptap-text-direction';
import { FileEmbedTiptapNodeView } from './node-views/file-embed-tiptap-nodeview';
import Link from '@tiptap/extension-link';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { TiptapEmojiService } from './emoji/tiptap-emoji-service';
import Collaboration from '@tiptap/extension-collaboration';
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { EditorView } from 'prosemirror-view';
import UniqueID from '@tiptap-pro/extension-unique-id';
import { CollabLocator } from '../../../../base/diagram/locator/collab-locator';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import { Highlight } from '@tiptap/extension-highlight';
import { FontSize, Color, FontFamily } from './custom-extensions/text-styles';
import { lodashThrottle } from '@creately/rx-lodash';
import { DiagramCommandEvent } from '../../../../editor/diagram/command/diagram-command-event';
import { isChangeOrigin } from '@tiptap/extension-collaboration';
import Mention from '@tiptap/extension-mention';
import { TiptapMentionsService } from './mentions/tiptap-mentions-service';

// NOTE: Following added to fix "Cannot read property 'matchesNode' of null (TipTap for ~React~ Angular )""
// Error thrown when calling the tiptap editor destroy()
// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState( this, state ) {
    if ( !this.docView ) {
         return;
    } // This prevents the matchesNode error on hot reloads
    try {
        this.updateStateInner( state, this.state.plugins !== state.plugins );
    } catch ( error ) {
        Logger.error( 'TipTap EditorView Failed to update inner state', error );
    }
};

// tslint:disable:max-file-line-count
// tslint:disable:member-ordering
/**
 * Tiptap Editor component for Rich TextInput
 */
@Component({
    selector: 'tiptap-editor-component',
    host: { '[id]': 'edataId || diagramId', '[class]' : 'id' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './tiptap-editor.html',
    styleUrls: [
        './tiptap-editor.cmp.scss',
        './node-views/file-embed-tiptap-nodeview.scss',
        './node-views/iframe-node-view.scss',
    ],
})
export class TiptapEditor extends DynamicComponent implements IDataItemUIControl<ITiptapData>,
    AfterViewInit, OnDestroy {

    public id;

    /**
     * The Tiptap editor instance
     */
    public editor: Editor;

    /**
     * emits the tiptap editor instance when its created
     */
    public editorSubject: BehaviorSubject<Editor>;

    /**
     * associated diagramId
     */
    @Input()
    public diagramId: string;

    /**
     * associated edataId ( Optional )
     */
    @Input()
    public edataId: string;

    @Input()
    public showTableToolbar: boolean = true;

    /**
     * The callback to transform pasted content. Do nothing by default.
     * @param data
     * @returns
     */
    public transformPasted: Function = data => data;
    public transformPastedHTML: Function = data => data;
    public transformPastedText: Function = data => data;

    public handleKeydown: Function = ( view, event ) => false;

    /**
     * The text editor element
     */
     @ViewChild( 'editor' )
     public editorElement: ElementRef;


    /**
     * y js document
     */
    public ydoc;

    /**
     * Hocuspocus ( websocket server to enebale collaborative editing for tiptap ) Provider
     */
    public hpProvider: any;

    /**
     * Index db provider for y js to preserve the editor content to recover data
     * from unexpected sutuations i.e refresh/close browser etc.
     */
    public localProvider;

    /**
     * Dynamic angular components are rendered in this viewContainerRef
     */
    @ViewChild( 'viewContainerRef', { read: ViewContainerRef, static: false })
    public viewContainerRef: ViewContainerRef;

    /**
     * This subject emits when the selection changes
     */
    public selectionSubject: BehaviorSubject<Editor>;

    /**
     * This subject is to make the editor view only
     */
    @Input()
    public viewOnly: boolean = false;

    /**
     * This subject is to make the editor view only
     */
    @Input()
    public placeHolderText: string;

    /**
     * This subject emits when the editor is focused in
     */
    public focused: Subject<any>;

    /**
     * Emits the value of the UI component
     */
    public change: Subject<ITiptapData>;

    /**
     * Subject to set data
     */
    public setDataSubject: BehaviorSubject<any>;

    /**
     * Emits when the doc is synced with tiptap collab server
     */
    public hpSynced: Subject<any>;

    /**
     * Emits when the doc is synced with the indexed db
     */
    public localSynced: BehaviorSubject<any>;

    /**
     * Emits when the tiptap collab server is unreachable
     */
    public hpClosed: BehaviorSubject<boolean>;

    /**
     * Emits when the websocket connection is opned
     */
    public hpOpened: BehaviorSubject<boolean>;


    @Input()
    public shapeIdSubject: BehaviorSubject<string>;

    /**
     * Emits when the websocket authenticationFailed is failed
     */
    public authenticationFailed: BehaviorSubject<boolean>;

    /**
     * Emits when a creately link is pasted
     */
    protected createlyLinkPasted: Subject<any>;

    /**
     * Regex to capture creately links
     */
    protected regex = /(?:^|\s)http[s]?:\/\/([a-zA-Z\d-]+\.)*creately\.com\/d\/(\w+)?/g;

    protected subs = [];

    protected nodeViews: INodeView[] = [];

    protected rtlTypes = [ 'heading', 'paragraph', 'bulletList',
        'orderedList', 'detailsSummary', 'detailsContent' ];
    protected textDirection = 'ltr';

    /**
     * If the editor should not be closed, when the focus is set to another
     * element, this attribute should be added to that element
     * e.g. <div data-keep-text-open="true" ><input></input></div>
     */
     protected keepEditorOpenAttribute = 'data-keep-text-open';

    /**
     * This property holds observers created in listenToEvent method.
     */
     protected eventEmitters: Array<any> = [];

    /**
     * List of web safe sans-serif fonts
     */
     private sanSerifFonts = [ 'arial', 'helvetica', 'sans-serif' ];

     /**
      * List of web safe serif fonts
      * FIXME: For some times, we have used double quotation
      * because that item has spaces and the string interpretaion
      * adding double quotation by default. This entire font selection
      * should be refactored properly. Example: if we add two fonts
      * to the same text and when we highlight the text, it represents
      * the actual font family value in the font component instead of the
      * dropdown value.
      */
     private serifFonts = [ '"Times New Roman"', '"Courier New"', 'Courier', 'Georgia', 'serif' ];


    /**
     * Constructor
     */
    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected injector: Injector,
        protected commandService: CommandService,
        protected state: StateService<any, any>,
        protected ll: DiagramLocatorLocator,
        protected collabLocator: CollabLocator,
        protected auth: Authentication,
        protected translate: TranslateService,
        protected vToD: ViewportToDiagramCoordinate,
        protected elementRef: ElementRef,
        protected userLocator: UserLocator,
    ) {
            super( componentFactoryResolver, injector );
            this.ydoc = new Y.Doc();
            this.focused = new Subject();
            this.change = new Subject();
            this.createlyLinkPasted = new Subject();
            this.hpSynced = new Subject();
            this.localSynced = new BehaviorSubject( false );
            this.hpClosed = new BehaviorSubject( false );
            this.hpOpened = new BehaviorSubject( false );
            this.authenticationFailed = new BehaviorSubject( false );
            this.selectionSubject = new BehaviorSubject( null );
            this.setDataSubject = new BehaviorSubject({});
            this.editorSubject = new BehaviorSubject( null );
            this.shapeIdSubject = new BehaviorSubject( null );
            this.placeHolderText = this.translate.instant( 'TIPTAP_EDITOR.PLACEHOLDER' );
    }

    public ngAfterViewInit() {
        this.nodeViews.push(
            DataItemTiptapNodeView.create( this.injector, this.viewContainerRef, this.diagramId ),
            TiptapPrimaryTextNodeView.create( this.injector, this.viewContainerRef, this.diagramId ) as any,
            TiptapPrimaryTextSummary.create( this.injector, this.viewContainerRef, this.diagramId ) as any,
            TiptapPrimaryTextContent.create() as any,
            ChildEditorNodeView.create(),
            ImageTiptapNodeView.create(),
            IframeNodeView.create( this.injector, this.diagramId ),
        );

        // FIXME: This a tempt fix to disable following node views for shape texts
        if ( this.id.includes( 'notes' )) {
            this.nodeViews.push(
                FileEmbedTiptapNodeView.create( this.injector, this.viewContainerRef, this.diagramId ),
                DiagramLinkTiptapNodeView.create( this.injector, this.viewContainerRef, this.diagramId ),
            );
        }
        if ( this.viewOnly ) {
            this.initTiptapViewOnly();
        } else {
            this.localProvider = new IndexeddbPersistence( this.syncId , this.ydoc );
            this.localProvider.on( 'synced', () => {
                this.localSynced.next( true );
            });
            this.initCollabEdit();
            const currentUser = this.state.get( 'CurrentUser' );
            this.collabLocator.getCollab( currentUser ).pipe(
                switchMap( collab => collab ? of( collab ) : this.userLocator.getUserInfo( currentUser )),
                map( u => {
                    if ( !u ) {
                        const user = new UserInfoModel( 'anonymoususer' );
                        user.firstName = 'Anonymous';
                        return user;
                    }
                    return u;
                }),
                take( 1 ),
            ).subscribe( user => {
                let userColor;
                if ( user instanceof CollabModel ) {
                    userColor = user.displayColor;
                } else {
                    userColor = user.getColor();
                }
                this.initTiptapEdit( user, userColor );
            });
        }
    }

    protected initTiptapEdit( user: UserInfoModel, userDisplayColor: string ) {
        const slashComdSvc = TiptapSlashCommandsService
            .create( this.injector, this.viewContainerRef, this.diagramId, this.shapeIdSubject );
        const emojiSvc = TiptapEmojiService
            .create( this.injector, this.viewContainerRef );
        const mentionsSvc = TiptapMentionsService
            .create( this.injector, this.viewContainerRef );
        this.subs.push( ...slashComdSvc.subs );

        const element: HTMLElement = this.elementRef.nativeElement.querySelector( '.tiptap-editor' );
        this.editor = new Editor({
            element,
            editorProps: {
                attributes: {
                    class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
                },
                handleDOMEvents: {
                    keydown: ( view, event ) => this.handleKeydown( view, event  ),
                },
                // FIXME: Ideally this should be done in the addPasteRules hook in the
                // DiagramLinkTiptapNodeView but it's not working due to a tiptap issue
                transformPastedText: text  => {
                    Tracker.track( 'canvas.text.menu.rightclick.load' );
                    try { // Disallow copy-pasting shapes into the text editor
                        const data =  JSON.parse( text );
                        if ( data?.shapesToCopy?.[0]) {
                            const def = data.shapesToCopy[0];
                            const keys = [ 'defId', 'version', 'id', 'type' ];
                            if ( keys.every( item => def.data.hasOwnProperty( item ))) {
                                return '';
                            }
                        }
                    } catch ( error ) {
                    }
                    this.regex.lastIndex = 0;
                    const match = this.regex.exec( text );
                    // FIXME: ( this.id.includes( 'notes' )) is fix to disable followin node views for shape texts
                    if ( match && this.id.includes( 'notes' )) {
                        setTimeout(() => {
                            this.createlyLinkPasted.next( match.input );
                        }, 100 );
                        return '';
                    }
                    return this.transformPastedText( text );
                },
                transformPastedHTML: html  => {
                    const div = document.createElement( 'div' );
                    div.innerHTML = html;
                    this.regex.lastIndex = 0;
                    const match = this.regex.exec( div.textContent.trim());
                    if ( match ) {
                        setTimeout(() => {
                            this.createlyLinkPasted.next( match.input );
                        }, 100 );
                        return '';
                    }
                    html = html.replace( /<tiptap-child-editor-node([^>]*)>((?:.|\n)*?)<\/tiptap-child-editor-node>/g, '<div$1>$2</div>' );
                    html = html.replace( /<primary-text-node\b[^>]*>([\s\S]*?)<\/primary-text-node>/g, '<h1>$1</h1>' );
                    html = html.replace( / data-pm-slice="[^"]*"/g, '' );
                    html = html.replace( /<summary([^>]*)>(.*?)<\/summary>/g, '<div$1>$2</div>' );

                    return this.transformPastedHTML( html );
                },
                transformPasted: html  => this.transformPasted( html ),
            },

            extensions: [
                TextStyle,
                Color.configure({
                    types: [ 'textStyle', 'bulletList', 'orderedList' ],
                }),
                StarterKit.configure({
                    history: false, // Collab extention handles history
                }),
                TextAlign.configure({
                    types: [ 'heading', 'paragraph', 'image' ],
                    defaultAlignment: 'none',
                }),
                FontSize.configure({
                    types: [ 'textStyle', 'bulletList', 'orderedList' ],
                }),
                FontFamily.configure({
                    types: [ 'textStyle', 'bulletList', 'orderedList' ],
                }),
                Link,
                Highlight.configure({ multicolor: true }),
                Table.configure({
                    resizable: true,
                }),
                Typography,
                Emoji.configure({
                    emojis: gitHubEmojis,
                    enableEmoticons: true,
                    suggestion: emojiSvc,
                }),
                TableRow,
                TableHeader,
                TableCell,
                TextDirection.configure({
                    types: this.rtlTypes,
                }),
                UniqueID.configure({
                    types: [ 'tiptapChildEditor' ],
                    filterTransaction: transaction => !isChangeOrigin( transaction ),
                }),
                Underline,
                TaskList.configure({
                    itemTypeName: 'taskItem',
                }),
                TaskItem.configure({
                    nested: true,
                }),
                Collaboration.configure({
                    document: this.ydoc,
                }),
                CollaborationCursor.configure({
                    provider: this.hpProvider,
                    user: { name: user.fullName, color: userDisplayColor },
                }),
                suggestionCmd.configure({
                    suggestion: slashComdSvc,
                }),
                Mention.configure({
                    suggestion: mentionsSvc,
                }),
                ...this.nodeViews.map(( nv: any ) => {
                    if ( nv.parentType ) {
                        return nv.parentType.extend( nv ) as any;
                    }
                    return Node.create( nv );
                }),
                Placeholder.configure({
                    emptyEditorClass: 'is-editor-empty',
                    includeChildren: true,
                    placeholder: ({ editor, node }) => {
                      if ( node.type.name === 'detailsSummary' ) {
                        return this.translate.instant( 'TIPTAP_EDITOR.EXTENSIONS.PRIMARY_TEXT_NODE.DETAILS_SUMMARY.PLACEHOLDER' );
                      }

                      const $pos = editor.view.state.selection.$anchor;
                      for ( let d = $pos.depth; d > 0; d-- ) {
                          const n = $pos.node( d );
                          if ( n.type.name === 'tiptapChildEditor' ) {
                                if ( n.content.size === 2 ) {
                                    return this.placeHolderText;
                                }
                                return '';
                          }
                      }
                      return editor.isEmpty ? this.placeHolderText : '';
                    },
                }),
            ],
            content: ``,
            onFocus: ({ editor, event }) => {
                this.focused.next( true );
                this.clearCachedSelection();
            },
            onSelectionUpdate: ({ editor }) => {
                // The selection has changed.
                this.selectionSubject.next( editor );
                setTimeout(() => {
                    this.handleTableToolbar( editor );
                }, 10 );
            },
        });

        // Added just for tracking
        ( this.editor as any ).getContext  = () => {
            if ( this.id.includes( 'notes' )) {
                return of( 'shape-data' );
            }
            return this.ll.forCurrent( false ).getShapeOnce( this.shapeIdSubject.value ).pipe(
                map( model => {
                    if ( model.defId === TEXT_SHAPE_DEF_ID ) {
                        return 'workspace';
                    }
                    return 'in-shape';
                }),
            );
        };

        this.editorSubject.next( this.editor );

        this.subs.push(
            this.listentToEditor( 300 ).subscribe(),
            this.listentToToolbar().subscribe(),
            this.focusedOut().subscribe( c => {
                this.change.next( this.getChildEditorHTML());
            }),
            this.createlyLinkPasted.subscribe( link => this.editor.commands.insertContent({
                type: 'diagramLinkNode',
                attrs: { link },
            })),

            // Set initial content only if the y doc is empty,
            // This is Requred to prevent from duplicating content
            this.hpSynced.pipe(
                switchMap(() => this.setDataSubject ),
                take( 1 ),
            ).subscribe( data => {
                if ( this.ydoc.toJSON().default === '' && !isEmpty( data )) {
                    this.editor.commands.setContent( data );
                }
            }),

            // When the tiptap editor is initialized, the content shold be set as usual if
            // the hocuspocus server is unreachable or authenticatoin fails.
            merge( this.hpClosed, this.authenticationFailed ).pipe(
                filter( v => !!v ),
                switchMap(() => this.setDataSubject ),
                take( 1 ),
            ).subscribe( data => {
                if ( this.ydoc.toJSON().default === '' && !isEmpty( data )) {
                    this.editor.commands.setContent( data );
                }
            }),

        );

    }

    /**
     * Returns the HTML of the current child editor
     */
    public getChildEditorHTML(): { shapeId, html } {
        let $pos = this.editor.view.state.selection.$anchor;
        if ( $pos.depth === 0 ) {
            $pos = this.editor.view.state.selection.$head;
        }
        for ( let d = $pos.depth; d > 0; d-- ) {
            const node = $pos.node( d );
            if ( node.type.name === 'tiptapChildEditor' ) {
                const shapeId = node.attrs.shapeId;
                const html = getHTMLFromFragment( node.content, this.editor.schema );
                return { shapeId, html };
            }
        }
    }

    protected initCollabEdit() {
        // FIXME: Can't import this module as usual due to
        // some type errors. Using require temporarily to ignore types.
        // tslint:disable:no-var-requires
        // tslint:disable:no-require-imports
        const hp = require( '@hocuspocus/provider' );
        this.hpProvider = new hp.HocuspocusProvider({
            url: AppConfig.get( 'TIPTAP_WS_URL' ),
            name: this.syncId,
            document: this.ydoc,
            token: this.auth.token,
            timeout: 50000,
            maxAttempts: 5,
        });
        this.hpProvider.on( 'synced', () => {
            this.hpSynced.next( true );
        });
        this.hpProvider.on( 'open', () => {
            this.hpOpened.next( true );
        });
        this.hpProvider.on( 'close', () => {
            this.hpClosed.next( true );
        });
        this.hpProvider.on( 'authenticationFailed', () => {
            this.authenticationFailed.next( true );
        });
    }

    protected get syncId() {
        if ( this.id === 'notesEdata' ) {
            return `${this.id}.${this.edataId}`;
        }

        return `${this.id}.${this.diagramId}`;
    }

    protected initTiptapViewOnly() {
        this.editor = new Editor({
            element: this.elementRef.nativeElement.querySelector( '.tiptap-editor' ),
            editorProps: {
                attributes: {
                    class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none',
                },
            },

            extensions: [
                TextStyle,
                Color,
                FontSize,
                FontFamily,
                TextAlign,
                StarterKit,
                TextAlign.configure({
                    types: [ 'heading', 'paragraph', 'image', 'span' ],
                }),
                TextDirection.configure({
                    types: this.rtlTypes,
                }),
                Link,
                Highlight.configure({ multicolor: true }),
                Typography,
                Emoji,
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,

                Underline,
                TaskList.configure({
                    itemTypeName: 'taskItem',
                }),
                TaskItem.configure({
                    nested: true,
                }),
                ...this.nodeViews.map(( nv: any ) => {
                    if ( nv.parentType ) {
                        return nv.parentType.extend( nv ) as any;
                    }
                    return Node.create( nv );
                }),
                Mention,
            ],
            content: ``,
        });
        this.editorSubject.next( this.editor );
    }

    /**
     * Sets data to the button.
     */
    public setData( data: IDataItem<any> ) {
        if ( data.value && data.value.ops ) { // Quill
            const converter = new QuillDeltaToHtmlConverter( data.value.ops );
            const html =  converter.convert();
            this.setDataSubject.next( html );
        } else {
            this.setDataSubject.next( data.value );
        }
    }

    /**
     * Unsubscribe from all subscriptions
     */
    public ngOnDestroy(): void {
        if ( this.hpProvider ) {
            // NOTE: Make sure 'destroy' is called after the websocket
            // is opned ( or unreachable )
            merge(
                this.hpOpened,
                this.hpClosed,
                this.authenticationFailed,
            ).pipe(
                filter( v => !!v ),
                take( 1 ),
            ).subscribe(() => {
                this.hpProvider.destroy();
            });
        }
        if ( this.localProvider ) {
            this.localProvider.clearData();
            this.localProvider.destroy();
        }
        this.nodeViews.forEach( nv => {
           nv.destroy();
        });

        if ( this.editor ) {
            this.editor.destroy();
        }
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
        while ( this.eventEmitters.length ) {
            this.eventEmitters.pop().complete();
        }
    }

    /**
     * Disable the editor
     */
    public disable() {
        const  editableElements = this.elementRef.nativeElement.querySelectorAll( '[contenteditable=true]' );
        for ( let i = 0; i < editableElements.length; i++ ) {
            editableElements[i].setAttribute( 'contenteditable', false );
        }
        const  inputs = this.elementRef.nativeElement.querySelectorAll( 'input' );
        for ( let i = 0; i < inputs.length; i++ ) {
            inputs[i].setAttribute( 'disabled', true );
        }
    }

    /**
     * Listen to the editor selection/caret changes and updates the TextEditorState state
     */
    protected listentToEditor( throttleTime: number ) {
        return this.getSelectionFormat().pipe(
            lodashThrottle( throttleTime, { leading: true, trailing: true }),
            tap(({ styles, selection }) => {
                if ( !( styles && selection )) {
                    return;
                }

                if ( this.editor?.isActive( 'image' )) {
                    return;
                }

                if ( typeof styles.color !== 'string' ) { // When multi colors are in selection, color becomes {}
                    styles.color = 'rgb(69, 85, 105)';
                }

                // NOTE: Adding this to avoid applying the actual font family
                // on the font component. This should be fixed by proper refactor
                // in the font selection. More details given in the font array.
                if ( styles && styles.font ) {
                    const sanserif = this.sanSerifFonts.join( ', ' );
                    if ( sanserif === styles.font ) {
                        styles.font = 'Sans-Serif';
                    } else {
                        const serif = this.serifFonts.join( ', ' );
                        if ( serif === styles.font ) {
                            styles.font = 'Serif';
                        }
                    }
                }

                // FIXME
                // When texts are deleted, editor.getSelectionFormat observable emits twice.
                // For the first emit, editor.getSelectionBounds returns NaN value for selection
                // but it returns the correct values for the second emit. This should be fixed from the carata end.
                // Temporary added NaN checks here.
                if ( !Number.isNaN( selection.x ) && !Number.isNaN( selection.y ) && !Number.isNaN( selection.width )) {
                    const editorBounds = this.bounds;
                    const pan = this.state.get( 'DiagramPan' );
                    const zoom = this.state.get( 'DiagramZoomLevel' );
                    this.state.set( 'TextEditorState-' + this.id,
                        { context: this.id, styles, selection, editorBounds, pan, zoom });
                }

                // When multi links are in selection, link becomes {}
                if ( typeof styles.link === 'string' && styles.link ) {
                    this.commandService.dispatch( DiagramCommandEvent.openHyperlinkEditor,
                        {
                            open: true,
                            link: styles.link,
                            state: 'view',
                            origin: 'selection',
                            stateContext: this.id,
                        });
                } else if ( this.state.get( 'HyperlinkEditState' ).open ) {
                    this.commandService.dispatch( DiagramCommandEvent.openHyperlinkEditor,
                        { open: false });
                }
            }),
        );
    }

    /**
     * Returns the bounds of the text editor
     */
     public get bounds(): Rectangle {
        const el = this.editorElement.nativeElement;
        const rect = el.getBoundingClientRect();
        const y = rect.top;
        const x = rect.left;
        const width = parseFloat( el.style.width );
        const height  = el.clientHeight;
        return new Rectangle( x, y, width, height );
    }

    protected selectionCache: { anchor: number, head: number };
    public getSelectionFormat() {
        return Observable.create( observer => {
            const handler: any = ({ editor }) => {
                if ( this.editor !== editor ) { // Added filter TiptapEditorSelectedAll custom event
                    return;
                }
                const state = this.state.get( 'EditingText' );
                if ( // FIXME: For Notes section text editor 'EditingText' state is not important.
                    // Check if the current editor is shapeText or notes here is not a great thing do.
                    ( this.id === 'shapeText' && state.open && this.shapeIdSubject.value === state.shapeId ) ||
                    ( this.id.includes( 'notes' ) && this.shapeIdSubject.value === this.state.get( 'Selected' )[0])
                ) {
                    const anchor = ( editor as Editor ).view.state.selection.anchor;
                    const head = ( editor as Editor ).view.state.selection.head;
                    const selection = this.getSelectionBounds( editor );
                    observer.next({
                        styles: this.styleTiptapToCarota( editor ),
                        selection,
                    });
                    if  ( anchor !== head ) {
                        this.selectionCache = { anchor, head };
                    }
                }
            };
            this.editor.on( 'selectionUpdate' as any, handler );
            // FIXME: selectionUpdate event is not triggered when the selection is set programetically
            // TiptapEditorSelectedAll custom event added to handle this case.
            window.addEventListener( 'TiptapEditorSelectedAll', handler );
            this.editor.on( 'update' as any, handler );
            this.eventEmitters.push( observer );
            return () => {
                // The observable was unsubscribed. So remove the observer from the list
                this.editor.off( 'selectionUpdate' as any, handler );
                this.editor.off( 'update' as any, handler );
                window.removeEventListener( 'TiptapEditorSelectedAll', handler );
                this.removeObserver( observer );
            };
        });
    }

    /**
     * Completes and Removes the given observer from the eventEmitters array
     * @param observer
     */
     protected removeObserver( observer: Observer<any> ) {
        observer.complete();
        const index = this.eventEmitters.findIndex( o => o === observer );
        if ( index > -1 ) {
            this.eventEmitters.splice( index, 1 );
        }
    }

    /**
     * Returns the bounds of the selected area
     */
     public styleTiptapToCarota( editor?: Editor ) {
        const format = {} as any;
        // Toggleable
        [ 'bold', 'italic', 'underline' ].forEach( f => {
                format[ f ] = editor.isActive( f );
        });

        format.strikeout = editor.isActive( 'strike' );

        const isLink = editor.isActive( 'link' );
        if ( isLink ) {
            const linkAttributes = editor.getAttributes( 'link' );
            format.link = linkAttributes.href;
        }

        format.align = editor.isActive({ textAlign: 'right' }) ? 'right' :
            editor.isActive({ textAlign: 'left' }) ? 'left' :
            editor.isActive({ textAlign: 'center' }) ? 'center' :
            editor.isActive({ textAlign: 'justify' }) ? 'justify' : 'center';
        const ts = editor.isActive( 'textStyle' );
        format.size = DEFUALT_TEXT_STYLES.size;
        if ( ts ) {
            const tsAttributes = editor.getAttributes( 'textStyle' );
            format.color = tsAttributes.color;
            format.size = parseFloat(  tsAttributes.fontSize || DEFUALT_TEXT_STYLES.size );
            format.font = tsAttributes.fontFamily;
        }
        return format;
    }


     public getSelectionBounds( editor?: Editor ) {
        const { state, view } = editor;
        const $posAnchor = state.selection.$anchor;
        const $posHead = state.selection.$head;
        const coordsAnchor = view.coordsAtPos( $posAnchor.pos );
        const coordsHead = view.coordsAtPos( $posHead.pos );
        let selectionLeft = Math.min( coordsAnchor.left, coordsHead.left );
        const selectionRight = Math.max( coordsAnchor.right, coordsHead.right );
        const selectionTop = Math.min( coordsAnchor.top, coordsHead.top );
        const selectionBottom = Math.max( coordsAnchor.bottom, coordsHead.bottom );
        const domNode = this.editor.view.dom;
        const b = domNode.getBoundingClientRect();

        const domSel = ( view as any ).domSelection();
        if ( domSel.rangeCount === 0 || ( domSel.rangeCount === 1 &&
            domSel.anchorNode?.data?.charCodeAt?.( 0 ) === 8203 )) { // 8203 => zero width space
            selectionLeft = selectionRight;
        }
        return {
            y: this.vToD.y( selectionTop - b.y ),
            x: this.vToD.x( selectionLeft - b.x ),
            width: this.vToD.width( Math.round(( selectionRight - selectionLeft ) * 10 ) / 10  ),
            height: this.vToD.width( Math.round(( selectionBottom - selectionTop ) * 10 ) / 10 ),
        };
    }

    protected listentToToolbar() {
        return this.state.changes( 'TextToolbarState-' + this.id ).pipe(
            tap(( styles: { styles: ITextContent, takeFocus: boolean }) => {
                if ( styles ) {
                    this.applyStyles( styles );
                }
            }),
        );
    }

    public applyStyles( data: { styles: ITextContent, takeFocus: boolean }) {
        const chain = data.takeFocus ? this.editor?.chain().focus() : this.editor?.chain();
        if ( data.styles.align ) {
            chain.setTextAlign( data.styles.align );
        }
        if ( data.styles.bold ) {
            chain.setBold();
        }
        if ( data.styles.bold === false ) {
            chain.unsetBold();
        }

        if ( data.styles.italic ) {
            chain.setItalic();
        }
        if ( data.styles.italic === false ) {
            chain.unsetItalic();
        }

        if ( data.styles.underline ) {
            chain.setUnderline();
        }
        if ( data.styles.underline === false ) {
            chain.unsetUnderline();
        }

        if ( data.styles.strikeout ) {
            chain.setStrike();
        }
        if ( data.styles.strikeout === false ) {
            chain.unsetStrike();
        }

        if ( data.styles.color ) {
            chain.setColor( data.styles.color );
        }
        if ( data.styles.font ) {
            chain.setFontFamily( data.styles.font );
        }

        if ( data.styles.size ) {
            chain.setFontSize( data.styles.size + 'pt' );
        }

        if ( data.styles.link ) {
            chain
                .extendMarkRange( 'link' )
                .setColor( 'rgb(94, 156, 211)' )
                .setUnderline()
                .setLink({ href: data.styles.link });
        }
        if ( data.styles.link === null ) {
            chain
                .extendMarkRange( 'link' )
                .unsetColor()
                .unsetUnderline()
                .unsetLink();
        }


        if ( data.takeFocus ) {
            // setTimeout(() => {
            //     this.editor.commands.setTextSelection({
            //         to: this.editor?.state.selection.head,
            //         from: this.editor?.state.selection.anchor,
            //     });
            //     this.editor.commands.focus();
            // }, 3000 );
            // chain
            //     .setTextSelection({
            //         from: this.editor?.state.selection.from,
            //         to: this.editor?.state.selection.to,
            //     })
            //     .focus()
            //     .run();
            // return;
        }
        chain.run();
    }


    /**
     * This observable emits when the user clicks outside this component.
     */
    protected focusedOut() {
        const element = document.body;
        const editor = this.elementRef.nativeElement;
        const fOut = fromEvent( element, 'mouseup' );
        return this.focused.pipe(
        switchMap(() => fOut.pipe(
            filter(( e: MouseEvent ) => editor && !!e.target ),
            filter(( e: MouseEvent ) => {
                let p: HTMLElement = e.target as any;
                let outside = true;
                while ( p !== element ) {
                    if (( editor.contains( document.activeElement ) ||
                        editor.tagName === p.tagName && p.classList.contains( this.id )) ||
                        !p.parentElement ) {
                            outside = false;
                            break;
                    }
                    p = p.parentElement;
                }
                // FIXME: Clicking on angular material components should not focus out the tiptap editor
                // `.cdk-overlay-pane` element is added to the dom root level when
                // agular material ui controls are opened e.g. date picker, people picker etc.
                // Find a better solution
                const angularMat = document.querySelector( '.cdk-overlay-pane' );
                if ( angularMat ) {
                    return false;
                }
                if ( outside ) {
                    let canFoucsOut = true;
                    const elems = document.querySelectorAll( `*[${this.keepEditorOpenAttribute}="true"]` );
                    elems.forEach( el => {
                        canFoucsOut = canFoucsOut && !el.contains( document.activeElement );
                    });
                    canFoucsOut ? this.clearCachedSelection() : this.showCachedSelection();
                    return canFoucsOut;
                }
                return outside;
            }),
            take( 1 ),
            tap(() => {
                const event = new Event( 'tiptap-blur' );
                ( this.editorElement.nativeElement as HTMLElement ).firstElementChild.dispatchEvent( event );
            }),
        )),
        );
    }

    /**
     * Adds a light grey background for the editor texts to indicate the cached selection.
     * This is done silently so that text-change event won't emit.
     * Currently Creatly is not supporting backgournd text color and if supports in future,
     * this method may change to consider exisiting backgrounds.
     */
     protected showCachedSelection() {
        if ( !this.selectionCache || this.editor.isFocused ) {
            return;
        }
        const length = Math.abs( this.selectionCache.anchor - this.selectionCache.head );
        if ( length > 0 ) {
            this.editor.chain().focus().setTextSelection({
                from: this.selectionCache.anchor,
                to: this.selectionCache.head,
            }).setHighlight({ color: '#b7b7b759' }).run();
        }
    }

    /**
     * Removes the cached selection
     */
     protected clearCachedSelection() {
        if ( this.selectionCache ) {
            this.editor.chain().focus().setTextSelection({
                from: this.selectionCache.anchor,
                to: this.selectionCache.head,
            }).unsetHighlight().run();
        }
        this.selectionCache = null;
    }

    /**
     * Finds the table which is currently being edited
     * and place toolbar on top-middle of the table
     * @param state
     */
    protected handleTableToolbar( editor ) {
        const { state, view } = editor;
        const el = this.elementRef.nativeElement.querySelector( '.tiptap-table-toolbar' );
        const dom = this.elementRef.nativeElement.querySelector( '.tiptap-editor' );

        const showTableToolbar = dom && el && editor.can().deleteTable();
        if ( !showTableToolbar ) {
            if ( el ) {
                el.style.display = 'none';
            }
            return;
        }

        el.style.display = 'block';
        const $pos = state.selection.$anchor;
        for ( let d = $pos.depth; d > 0; d-- ) {
            const node = $pos.node( d );
            if ( node.type.spec.tableRole === 'table' ) {
                const table = view.domAtPos( $pos.before( d ) + 1 );
                if ( table && table.node ) {
                    const toolbarBounds = el.getBoundingClientRect();
                    const pos = table.node.getBoundingClientRect();
                    const domBounds = dom.getBoundingClientRect();
                    const x = pos.x - domBounds.x + domBounds.width / 2 - toolbarBounds.width / 2 - 12;
                    const y = pos.y - domBounds.y  - toolbarBounds.height - 5;
                    el.style.transform = `translate3d(${ x }px, ${ y }px, 0px)`;
                }
                return;
            }
        }
    }

}

export interface ITiptapData {
    shapeId: string;
    html: string;
}
